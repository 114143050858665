<template>
    <div class="px-3 py-2">
        <b-sidebar aria-labelledby="sidebar-no-header-title" width="900px" no-header id="emp_custody" backdrop style="direction:ltr" right title="اضافة مصروف دوري" shadow >
            <template #default="{ hide }">
                <div class="m-1 text-start" style="direction:rtl">
                    <div style="width:140px;margin-right:10px;background:#000;color:#FFF;border-radius:5px" class="btn-primary btn-sm text-center">
                        <span style="font-size:.8em;">{{$parent.lang.emp_custody}}</span>
                    </div>
                    <div @click="hide" id="cdedhideExp" style="width:100px;text-align:center;float:left;margin-left:5px;margin-top:-25px;cursor:pointer" class="btn-danger btn-sm">
                        <span>{{$parent.lang.close}}</span>
                    </div>
                </div>
                <v-divider></v-divider>
                <div class="px-3 py-2" :style="`direction:`+$parent.lang.dir">
                    <v-form ref="apform">
                        <v-row>
                            <v-col cols="12" md="12" sm="12">
                                <b-table-simple>
                                    <thead>
                                        <tr>
                                            <th class="text-center backBlack">{{ $parent.lang.date }}</th>
                                            <th class="text-center backBlack">{{ $parent.lang.description }}</th>
                                            <th class="text-center backBlack">{{ $parent.lang.qtty }}</th>
                                            <th class="text-center backBlack">{{ $parent.lang.item_price }}</th>
                                            <th class="text-center backBlack">{{ $parent.lang.total }}</th>
                                            <th class="text-center backBlack">{{ $parent.lang.status }}</th>
                                            <th class="text-center backBlue">{{ $parent.lang.action }}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item,index) in custody" :key="index">
                                            <td class="text-center" style="width:100px;">
                                                <b-form-input class="inborder" type="date" v-model="custody[index].received_date" />
                                            </td>
                                            <td class="text-center">
                                                <b-form-input class="inborder" v-model="custody[index].description" />
                                            </td>
                                            <td class="text-center" style="width:90px;">
                                                <b-form-input class="inborder" v-model="custody[index].qty" />
                                            </td>
                                            <td class="text-center" style="width:90px;">
                                                <b-form-input class="inborder" v-model="custody[index].item_price" />
                                            </td>
                                            <td class="text-center" style="width:90px;">
                                                <b-form-input class="inborder" v-model="custody[index].total" />
                                            </td>
                                            <th class="text-center" style="width:120px;">
                                                <b-form-select class="selborder" :options="cStatuses" v-model="custody[index].c_status" />
                                            </th>
                                            <td class="text-center backRed" style="cursor:pointer;width:80px;" @click="delItem(index)">
                                                {{ $parent.lang.delete }}
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <th class="text-center">
                                                <b-form-input class="inborder" type="date" v-model="newrow.received_date" />
                                            </th>
                                            <th class="text-center">
                                                <b-form-input class="inborder" v-model="newrow.description" />
                                            </th>
                                            <th class="text-center" style="width:90px;">
                                                <b-form-input class="inborder" v-model="newrow.qty" />
                                            </th>
                                            <th class="text-center" style="width:90px;">
                                                <b-form-input class="inborder" v-model="newrow.item_price" />
                                            </th>
                                            <th class="text-center" style="width:90px;">
                                                <b-form-input class="inborder" v-model="newrow.total" />
                                            </th>
                                            <th class="text-center" style="width:90px;">
                                                <b-form-select class="selborder" :options="cStatuses" v-model="newrow.c_status" />
                                            </th>
                                            <th class="text-center backBlue" style="cursor:pointer;width:80px;" @click="addItem()">
                                                {{ $parent.lang.add }}
                                            </th>
                                        </tr>
                                    </tfoot>
                                </b-table-simple>
                            </v-col>
                        </v-row>
                    </v-form>
                </div>
            </template>
            <template #footer="" class="shadow">
                <div class="d-flex text-light align-items-center px-3 py-2">
                    <b-button type="button" variant="success" @click="addit()" class="ma-2" style="width:100px;">{{$parent.lang.add}}</b-button>
                </div>
            </template>
        </b-sidebar>
    </div>
</template>

<script>
import axios from 'axios'
import {SnotifyPosition} from 'vue-snotify';
export default{
    data() {
        return {
            payment_type: 1,
            empid: 0,
            amount: '',
            paytypes: [],
            custody: [],
            fieldRules: [
                v => !!v || this.$parent.lang.required_field
            ],
            sd: {
                day: (new Date()).getDate(),
                month: (new Date()).getMonth() + 1,
                year: (new Date()).getFullYear(),
            },
            newrow: {
                description: '',
                qty: 1,
                item_price: '',
                total: '',
                received_date: '',
                c_status: 1,
                id: 0
            },
            cusStatus: []
        }
    },
    computed: {
        payment_date: function(){
            return `${this.sd.year}-${('0'+this.sd.month).slice(-2)}-${('0'+this.sd.day).slice(-2)}`;
        },
        cStatuses: function()
        {
            let t = [];
            for(let i=0;i<this.cusStatus.length;i++){
                t.push({text: this.cusStatus[i][`name_${this.$parent.lang.langname}`], value:this.cusStatus[i].value })
            }
            return t;
        }
    },
    created(){
        this.getPyamentType();
    },
    methods: {
        delItem(index) {
            this.custody.splice(index)
        },
        addItem() {
            if(this.newrow.description == '') return false;
            if(this.newrow.received_date == '') return false;
            if(this.newrow.qty == '') this.newrow.qty = 1;
            if(this.newrow.item_price == '') this.newrow.item_price = 0;
            if(this.newrow.total == '') this.newrow.total = 0;
            this.custody.push(this.newrow);
            this.newrow = {
                description: '',
                qty: 1,
                item_price: '',
                total: '',
                received_date: '',
                c_status: 1,
                id: 0
            }
        },
        getEmpl()
        {
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('type','getEmpTCust');
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[id]',this.empid);
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                const res = response.data;
                this.custody = response.data.results.data
            })
        },
        getStatic()
        {
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('type','getStaticOptions');
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[phrase]',"custodyStatus");
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                const res = response.data;
                this.cusStatus = res.results.data;
            })
        },
        getPyamentType(){
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('type','getPayTypes');
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[id]',"all");
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                const res = response.data;
                this.payTypeOp = [];
                for(let i=0;i<res.results.data.length;i++){
                    this.paytypes.push({
                        text: this.$parent.lang.langname == 'ar' ? res.results.data[i].type_name : res.results.data[i].type_name_en,
                        value: res.results.data[i].id
                    })
                }
            })
        },
        addit() {
            let vald = true;
            let message = '';
            if(this.custody.length == 0){
                vald = false;
                message = this.$parent.lang.you_can_not_add_empty_custody;
            }
            
            if(!vald){
                this.$snotify.error(message, 'تنبيه', {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.$parent.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                return false;
            }
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("type","addTCustody");
            post.append("auth",this.$cookies.get(this.$COOKIEPhase))
            post.append("data[empid]",this.empid);
            post.append("data[custody]",JSON.stringify(this.custody));
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                // // console.log(response.data);
                this.amount = '',  
                this.getEml();   
                this.$snotify.error(this.$parent.lang.added_successfully, 'تنبيه', {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.$parent.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                return false;          
            })
        }
    },
}
</script>
<style scoped>
.flexTay{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    justify-items: center;
}
.flexTay select{
    margin-inline-end: 2px;
}
</style>